document.addEventListener("turbo:load", function (event) {
  document.querySelectorAll(".removeable-image").forEach((container) => {
    const button = container.querySelector('button');
    const checkbox = container.querySelector('input[type=checkbox]');
    button.addEventListener('click', function (e) {
      e.preventDefault();
      container.classList.add('d-none');
      checkbox.checked = true;
    });
  });
});
