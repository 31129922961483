import {keymap, EditorView, placeholder} from "@codemirror/view"
import {defaultKeymap, history, historyKeymap} from "@codemirror/commands"
import {syntaxHighlighting, defaultHighlightStyle} from "@codemirror/language"
import {javascript} from "@codemirror/lang-javascript"
import {indentWithTab} from "@codemirror/commands"
import {html} from "@codemirror/lang-html"
import {css} from "@codemirror/lang-css"


function editorFromTextArea(textarea, {extensions, hintText, minHeight, maxHeight}) {
  // Taken from migration guide v5 to v6. CodeMirror.fromTextArea being removed
  // https://codemirror.net/docs/migration/
  let view = new EditorView({
    doc: textarea.value,
    extensions: [
      history(),
      keymap.of([...defaultKeymap, ...historyKeymap, indentWithTab]),
      syntaxHighlighting(defaultHighlightStyle),
      placeholder(hintText),
      ...(extensions || [])
    ]
  });

  if (minHeight) view.dom.style.minHeight = `${minHeight}px`;
  if (maxHeight)  view.dom.style.maxHeight = `${maxHeight}px`;

  textarea.parentNode.insertBefore(view.dom, textarea);
  textarea.style.display = "none";

  if (textarea.form) {
    textarea.form.addEventListener("submit", () => {
      textarea.value = view.state.doc.toString();
    });
  }

  return view;
}


document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.codemirror-text-editor').forEach((textarea, i) => {
    const readOnly = textarea.dataset.readonly === "true";
    const language = textarea.dataset.language || 'html';
    const extensions = [];

    if (language === "css") {
      extensions.push(css());
    } else if (language === "html") {
      extensions.push(html());
    } else if (language === "javascript") {
      extensions.push(javascript());
    }

    if (readOnly) {
      extensions.push(EditorView.contentAttributes.of({ contenteditable: false }));
    }

    const view = editorFromTextArea(textarea, {
      extensions,
      hintText: textarea.placeholder,
      minHeight: textarea.dataset.minHeight,
      maxHeight: textarea.dataset.maxHeight
    });
  });
});
